import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Form from "../../components/form"
import WhyChooseUs from "../../components/whyChooseUs"

const ServiceTemplate = ({ data }) => {
  return (
    <Layout>
      <SEO title={`${data.service.title} in Essex`} />
      <div className="breakout overflow-hidden h-md">
        <Img
          alt={data.service.featuredImage.description}
          className="h-full object-cover object-center"
          fluid={data.service.featuredImage.fluid}
        />
      </div>
      <div
        style={{ transform: "translateY(-50%)", backdropFilter: "blur(5px)" }}
        className="col-left bg-sjh-dark bg-opacity-80"
      ></div>
      <div>
        <div
          style={{ transform: "translateY(-50%)", backdropFilter: "blur(5px)" }}
          className="bg-sjh-dark bg-opacity-80 text-white text-4xl py-5 pr-12 w-auto inline-block"
        >
          <h1>{data.service.title}</h1>
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-12 pb-12 my-prose text-lg">
        <div
          dangerouslySetInnerHTML={{
            __html: data.service.intro.childMarkdownRemark.html,
          }}
        />
        {data.service.highlights && (
          <div className="grid grid-flow-col gap-6">
            {data.service.highlights.map(({ text, icon }) => (
              <div
                className="flex flex-col text-center items-center"
                key={text}
              >
                <Img className="mb-2" fixed={icon.fixed} />
                <p className="text-sm leading-tight">{text}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <section className="py-12 breakout relative">
        <div className="bg-sjh-pale py-16 md:py-24 absolute top-0 left-0 w-full"></div>
        <div className="grid sm:grid-cols-2 gap-4 md:gap-12 breakout-inner">
          {data.service.gallery.map(({ fluid }) => (
            <Img
              style={{ paddingTop: "56%" }}
              className="h-0 object-cover object-center"
              fluid={fluid}
            />
          ))}
        </div>
      </section>
      <div className="hidden lg:block col-left bg-sjh-pale"></div>
      <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-span-5 p-5 lg:p-16 lg:pl-0 bg-sjh-pale">
          <h2 className="uppercase text-2xl mb-2">Request a quote</h2>
          <p className="mb-4">
            Leave your name and number and we will get in touch with a free
            cleaning quote.
          </p>
          <Form name={data.service.title} />
        </div>
        <div className="col-span-12 lg:col-span-7 p-5 md:p-12 md:pr-0 pl-8 flex flex-col justify-between">
          <div
            className="my-prose text-sjh-dark pb-6 border-sjh-primary border-b-2 mb-6"
            dangerouslySetInnerHTML={{
              __html: data.service.moreInfo.childMarkdownRemark.html,
            }}
          />
          <WhyChooseUs />
        </div>
      </div>
    </Layout>
  )
}

export default ServiceTemplate

export const query = graphql`
  query($id: String) {
    service: contentfulService(id: { eq: $id }) {
      title
      featuredImage {
        description
        fluid(quality: 100, maxWidth: 1600, maxHeight: 900) {
          ...GatsbyContentfulFluid
        }
      }
      highlights {
        text
        icon {
          fixed(resizingBehavior: PAD, height: 50, width: 50) {
            ...GatsbyContentfulFixed
          }
        }
      }
      intro {
        childMarkdownRemark {
          html
        }
      }
      moreInfo {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
